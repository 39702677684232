import { defineStore } from "pinia";
import { Place, PlaceType } from "@/models/place";
import { Pagination } from "@/models/pagination";
import { plainToClass } from "class-transformer";
import { useMasterDataStore } from "@/stores/master_data";
import prefectures from "@/assets/data/prefectures.json";

export const useSearchFormStore = defineStore("search_form", {
  state: () => {
    return {
      // keyword のような初期値をセットするとVTabsの初期selectedが機能しない
      // ref. https://github.com/vuetifyjs/vuetify/issues/17897
      placeType: "",
      searchType: "",
      prefectures_checked: [],
      tags_checked: [],
      keyword: "",
      places: [],
      pagination: new Pagination(),
    };
  },
  getters: {
    placeTypeLabel() {
      switch (this.placeType) {
        case PlaceType.DOG_RUN:
          return "ドッグラン";
        case PlaceType.DOG_CAFE:
          return "カフェ";
        case PlaceType.DOG_HOTEL:
          return "ホテル";
        case PlaceType.PET_SALON:
          return "サロン";
        case PlaceType.CAMPSITE:
          return "キャンプ場";
      }
    },
    title() {
      let text = `${this.placeTypeLabel}の検索結果一覧`;
      if (this.features.length == 1) {
        text = `${this.features[0].name}の${this.placeTypeLabel}一覧`;
      }
      return text;
    },
    description() {
      let prefix = "全国";
      if (this.features.length == 1) {
        prefix = this.features[0].name;
      }
      return `${prefix}の${this.placeTypeLabel}をご紹介。\
        ドッグフルは日本最大級のドッグラン検索サイト。愛犬と過ごせる${this.placeTypeLabel}を検索できます。\
        `.replace(/\s+/g, "");
    },
    features(state) {
      const { $_ } = useNuxtApp();
      const masterDataStore = useMasterDataStore();
      const p_labels = this.prefectures_checked.map((sym) =>
        prefectures.find((p) => p.sym == sym),
      );
      const t_labels = this.tags_checked.map((sym) => {
        const key = `${$_.camelCase(this.placeType)}Tags`;
        const tag = masterDataStore[key].find((t) => t.slug == sym);
        return {
          name: tag.name,
          sym: tag.slug,
          is_tag: true,
        };
      });

      return p_labels.concat(t_labels);
    },
    route(state) {
      return {
        path: `/${state.placeType}/search`,
        query: {
          prefs: state.prefectures_checked,
          page: state.pagination.current_page,
          tags: state.tags_checked,
          k: state.keyword,
        },
      };
    },
  },
  actions: {
    async search(query: any) {
      const { $_ } = useNuxtApp();
      this.prefectures_checked = query["prefs"] ? query["prefs"] : [];
      this.tags_checked = query["tags"] ? query["tags"] : [];
      this.pagination.current_page = query["page"] ? query["page"] : 1;
      this.pagination.per_page = 10;

      const { places: json, pagination } = await apiFetch(`/places`, {
        query: {
          "place_type_tags[]": [this.placeType],
          "prefecture_sym[]": this.prefectures_checked,
          [`${$_.snakeCase(this.placeType)}_feature_tags[]`]: this.tags_checked,
          offset: this.pagination.per_page,
          page: this.pagination.current_page,
          keyword: this.keyword,
        },
      });
      this.pagination = plainToClass(Pagination, pagination);
      this.places = plainToClass(Place, json);
    },
    setPage(page) {
      this.pagination.current_page = page;
    },
    setPlaceType(type: PlaceType) {
      this.placeType = type;
    },
    closeChip(obj: { sym: string; is_tag: boolean }) {
      if (obj.is_tag) {
        this.tags_checked = this.tags_checked.filter((tc) => tc != obj.sym);
      } else {
        this.prefectures_checked = this.prefectures_checked.filter(
          (pc) => pc != obj.sym,
        );
      }
    },
    setKeyword(text: string) {
      const { $_ } = useNuxtApp();
      this.keyword = $_.trim(text);
    },
  },
});
