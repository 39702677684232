<script lang="ts" setup>
import { plainToClass } from "class-transformer";
import { Tag } from "@/models/tag";
import { useMasterDataStore } from "@/stores/master_data";
import { useSearchFormStore } from "@/stores/search_form";
import { useDisplay } from "vuetify";

const searchFormStore = useSearchFormStore();
const { mobile } = useDisplay();

const props = defineProps({
  placeType: "dog-run" | "dog-cafe" | "dog-hotel" | "pet-salon" | "campsite",
});

function dialogUpdated(isActive) {
  // submitされたとき
  if (!isActive) {
    return navigateTo(searchFormStore.route);
  }
}
const masterDataStore = useMasterDataStore();

const tags = computed(() => {
  switch (props.placeType) {
    case "dog-run":
      return masterDataStore.dogRunTags;
      break;
    case "dog-cafe":
      return masterDataStore.dogCafeTags;
      break;
    case "dog-hotel":
      return masterDataStore.dogHotelTags;
      break;
    case "pet-salon":
      return masterDataStore.petSalonTags;
      break;
    case "campsite":
      return masterDataStore.campsiteTags;
      break;
    default:
      [];
  }
});
</script>

<template lang="pug">
v-dialog(
  transition="dialog-bottom-transition"
  width="auto"
  :fullscreen="mobile"
  @update:modelValue="dialogUpdated"
)
  template(v-slot:activator="{ props }")
    slot(
      name="dialog-activator"
      v-bind="props"
    )
  template(v-slot:default="{ isActive }")
    v-row(justify="space-around")
      v-col
        v-card(
          flat
        )
          v-toolbar(
            color="white"
            density="compact"
          )
            v-toolbar-title.text-center(
              class="text-subtitle-2"
            ) 特徴から探す
          v-divider
          v-card-text(
            style="height: calc(100vh - 200px);overflow-y: scroll"
          )
            v-container
              v-row(
                no-gutters
                align="center"
              )
                v-col(
                  cols="6"
                  md="4"
                  class="text-center"
                  v-for="tag in tags"
                  :key="tag.slug"
                )
                  v-checkbox(
                    color="#ff9800"
                    hide-details="true"
                    :label="tag.name",
                    v-model="searchFormStore.tags_checked",
                    :value="tag.slug"
                    class="text-sm-1"
                  )
          v-card-actions(
            class="justify-center bg-primary"
          )
            v-btn(
              variant="text"
              @click="isActive.value = false"
              class="text-h6 text-white font-weight-bold"
              block
            ) 絞り込む
</template>

<style lang="scss" scoped>
.v-label {
  color: rgba(
    var(--v-theme-on-surface),
    var(--v-high-emphasis-opacity)
  ) !important;
}
.v-card-text {
  padding: 1px;
}
</style>
