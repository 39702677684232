<script lang="ts" setup>
import areas from "~/assets/data/areas";
import prefectures from "~/assets/data/prefectures";
import { useSearchFormStore } from "@/stores/search_form";
import { useDisplay } from "vuetify";

const searchFormStore = useSearchFormStore();
const { mobile } = useDisplay();

const props = defineProps({
  placeType: "dog-run" | "dog-cafe" | "dog-hotel" | "pet-salon",
});

const getPrefectures = (area_id) => {
  return prefectures.filter((p) => p["area_id"] == area_id);
};

function dialogUpdated(isActive) {
  // submitされたとき
  if (!isActive) {
    return navigateTo(searchFormStore.route);
  }
}
</script>

<template lang="pug">
v-dialog(
  transition="dialog-bottom-transition"
  width="auto"
  :fullscreen="mobile"
  @update:model-value="dialogUpdated"
  location="center"
)
  template(v-slot:activator="{ props }")
    slot(
      name="dialog-activator"
      v-bind="props"
    )
  template(v-slot:default="{ isActive }")
    v-row(justify="center")
      v-col(cols="auto")
        v-card(
          flat
        )
          v-toolbar(
            color="white"
            density="compact"
          )
            v-toolbar-title.text-center(
              class="text-subtitle-2"
            ) 都道府県から探す
          v-divider
          v-card-text(
            style="height: calc(100vh - 200px);overflow-y: scroll"
          )
            v-container
              v-row(
                no-gutters
                align="center"
                v-for="area in areas"
                :area="area"
                :key="area.id"
              )
                v-col(
                  cols="12"
                  md="2"
                  class="text-left"
                )
                  span(
                    class="font-weight-bold"
                  ) {{area.name}}
                v-col(
                  cols="12"
                  md="10"
                )
                  v-row(
                    no-gutters
                    align="center"
                  )
                    v-col(
                      cols="6"
                      md="2"
                      v-for="pref in getPrefectures(area.id)"
                      :key="pref.id",
                    )
                      v-checkbox(
                        color="#ff9800"
                        hide-details="true"
                        :label="pref.name",
                        v-model="searchFormStore.prefectures_checked",
                        :value="pref.sym"
                      )

                v-divider(
                  thickness="1px"
                  class="mb-5"
                )
          v-card-actions(
            class="justify-center bg-primary"
          )
            v-btn(
              variant="text"
              @click="isActive.value = false"
              class="text-h6 text-white font-weight-bold"
              block
            ) 絞り込む
</template>

<style lang="scss" scoped>
.v-label {
  color: rgba(
    var(--v-theme-on-surface),
    var(--v-high-emphasis-opacity)
  ) !important;
}
</style>
